const orders = [{
  productName: 'Babolat pure drive',
  productNumber: '*5631',
  paymentStatus: 'Due',
  shipping: 'pending',
},
{
  product_name: 'Babolat pure drive',
  productNumber: '*5631',
  paymentStatus: 'Due',
  shipping: 'pending',
},
{
  product_name: 'Babolat pure drive',
  productNumber: '*5631',
  paymentStatus: 'Due',
  shipping: 'pending',
},
{
  product_name: 'Babolat pure drive',
  productNumber: '*5631',
  paymentStatus: 'Due',
  shipping: 'pending',
}];

export default orders;
